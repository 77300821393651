<template>
    <v-tabs color="green">
        <v-tab>Attivi</v-tab>
        <v-tab>Archivio</v-tab>
        <v-tab-item>
            <OrdiniAttivi/>
        </v-tab-item>
        <v-tab-item>
            <OrdiniEvasi/>
        </v-tab-item>
    </v-tabs>
</template>

<script>
import OrdiniAttivi from "./ordini_attivi_mobile.vue"
import OrdiniEvasi from "./ordini_evasi_mobile.vue"
export default {
    components:{
        OrdiniAttivi,
        OrdiniEvasi
    }
}
</script>