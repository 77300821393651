<template>
  <v-row>
    <v-col cols="12">
      <OrdiniAttivi />
    </v-col>
    <v-col cols="12">
      <OrdiniEvasi/>
    </v-col>
  </v-row>
</template>

<script>
// import ordini_mixin from "../mixin/ordini_mixin";
import OrdiniAttivi from "./ordini_attivi_desktop.vue";
import OrdiniEvasi from "../desktop/ordini_evasi_desktop.vue"
//import OrdineAttivoEl from "./ordine_attivo_element.vue"
// import OrdineEvasoEl from "./ordine_evaso_element.vue";
// import NuovoOrdine from "@/components/Dashboard/Ordini_attivi/crea_ordine/crea_nuovo_ordine";
export default {
  components: {
    OrdiniAttivi,
    OrdiniEvasi
    //OrdineEvasoEl,
    //NuovoOrdine,
  },
  // mixins: [ordini_mixin],
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 300px;
  overflow-y: scroll;
}
</style>