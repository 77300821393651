<template>
  <OrdiniDesktop v-if="!$vuetify.breakpoint.mobile" />
  <OrdiniMobile v-else/>
</template>

<script>
import OrdiniDesktop from "@/components/Ordini/desktop/ordini_desktop.vue"
import OrdiniMobile from "@/components/Ordini/mobile/ordini_mobile.vue"
export default {
  components: {
    OrdiniDesktop,
    OrdiniMobile
  },
};
</script>