<template>
  <v-card :height="height">
    <v-card-title class="grey--text text-subtitle-1">
      Ordini Archiviati
      <v-btn icon @click="expand()" class="ml-4">
        <v-icon>{{
          is_expanded ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
      <v-autocomplete
        v-model="iva_cliente"
        :items="clienti"
        hide-details
        outlined
        dense
        rounded
        label="Cerca..."
        class="ml-4"
        item-text="nome"
        item-value="iva"
        @change="ricerca()"
        clearable
      ></v-autocomplete>
      <v-spacer />
    </v-card-title>
    <v-card-text :class="is_expanded ? 'scroll_extended' : 'scroll'">
      <v-divider class="mb-2" />
      <v-row class="font-weight-medium" no-gutters>
        <v-col cols="1"> ID </v-col>
        <v-col cols="1"> Data </v-col>
        <v-col cols="3"> Cliente </v-col>
        <v-col cols="1"> Consegna </v-col>
        <v-col cols="1" class="d-flex justify-end pr-2"> Totale </v-col>
        <v-col cols="1" class="d-flex justify-end pr-6">CC</v-col>
        <v-col cols="2"> Stato </v-col>
      </v-row>
      <v-divider class="mt-2" />
      <v-row v-if="!ordini_evasi.length" justify="center" class="pt-12">
        Nessun Ordine Evaso
      </v-row>
      <div v-else>
        <OrdineEvasoEl
          v-for="ordine in ordini_evasi"
          :ordine="ordine"
          :key="ordine._id.$oid"
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ordini_mixin from "../mixin/ordini_mixin";
import OrdineEvasoEl from "./ordine_evaso_element.vue";
export default {
  components: {
    OrdineEvasoEl,
  },
  mixins: [ordini_mixin],
  data() {
    return {
      is_expanded: false,
      height: 390,
    };
  },
  methods: {
    expand() {
      if (this.is_expanded) {
        this.is_expanded = false;
        this.height = 390;
      } else {
        this.is_expanded = true;
        this.height = 700;
      }
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 300px;
  overflow-y: scroll;
}

.scroll_extended {
  height: 610px;
  overflow-y: scroll;
}
</style>