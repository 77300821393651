export default {
  props: {
    item: Object,
  },
  data() {
    return {
      dialog_elimina: false,
      show_color: false,
      show_prod: false,
    };
  },
  computed: {
    nome() {
        return this.prodotto.nome.split(" in ")
    },
    tipo_vaso() {
      if (this.prodotto.unita == "1") {
        return "Vaso Singolo";
      } else {
        return "Cassa da " + this.prodotto.unita;
      }
    },
    prodotto() {
      return this.$store.getters.get_prodotto_raw_by_id(this.item.art);
    },
    tot_pezzi() {
      return this.item.colori
        .map((col) => parseInt(col.numero))
        .reduce((tot, num) => tot + num);
    },
    prezzo_tot() {
      let tot = this.tot_pezzi * this.item.prezzo;
      return tot.toFixed(2);
    },
    avatar_img() {
      return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.item.art + "?q=" + this.item.img_md5;
    },
  },
  methods: {
    delete_prod() {
      this.$store
        .dispatch("remove_prodotto_carrello", this.item.art)
        .then(() => {
          this.$emit("delete");
          this.dialog_elimina = false;
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.$router.push("/login");
          }
        });
    },
    edit_prod() {
      this.$emit("edit", this.item.art);
    },
    colore_img(id) {
      return (
        process.env.VUE_APP_API_ROOT +
        "/prodotto/" +
        this.item.art +
        "/img_colore/" +
        id
      );
    },
  },
};
