<template>
  <v-dialog fullscreen v-model="dialog">
    <v-card>
      <v-img height="100" :src="main_img">
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-card-title>
        <v-list-item class="ml-n4">
          <v-list-item-avatar>
            <v-avatar>
              <v-img :src="avatar_img" />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title
              class="primary--text font-weight-light font-italic"
            >
              <span style="font-size: 22px">{{ nome }}</span>
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.nome_sc }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn icon @click="close_dial()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-card-title>
      <v-card-text>
        <v-row align="center" class="mt-2">
          <v-col cols="12">
            <v-icon>mdi-information</v-icon> INFORMAZIONI
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            Unità di Vendita
            <br />
            <span class="headline">
              <v-icon>mdi-google-circles-communities</v-icon> {{ unita }}
            </span>
          </v-col>
          <v-col cols="6">
            Dimensione Vaso:
            <br />
            <span class="headline">
              <v-icon>mdi-diameter-variant</v-icon> {{ item.diametro }}
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            Prezzo Unitario
            <br />
            <v-icon>mdi-currency-eur</v-icon>
            <span class="headline"> {{ prezzo_unitario }} </span>
          </v-col>
          <v-col cols="6">
            Prezzo Confezione
            <br />
            <v-icon>mdi-currency-eur</v-icon>
            <span class="headline"> {{ prezzo_cliente }}</span>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-2">
          <v-col cols="12"> <v-icon>mdi-palette</v-icon> COLORI: </v-col>
          <v-col cols="">
            <template v-for="colore in item.colori">
              <ColorSelect :prodotto="item" :colore="colore" :key="colore.id" />
            </template>
          </v-col>
        </v-row>
        <v-textarea
          rows="1"
          label="Commento"
          :disabled="!exist"
          outlined
          hide-details
          v-model="commento"
          :rules="cmt_rules"
          counter="60"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="grey" @click="close_dial()">chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialog_carrello_mixin from "../../mixin/nuovo_ordine_mixin/listino_dettaglio_prodotto_mixin";
import ColorSelect from "../../desktop/crea_nuovo_ordine/menu_inserimento_qta.vue";

export default {
  components: {
    ColorSelect,
  },
  mixins: [dialog_carrello_mixin],
};
</script>