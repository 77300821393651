<template>
    <v-menu v-model="menu_opzioni">
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-1" color="grey" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-if="ordine.stato == 2" @click="riattiva_ordine()">
              <v-list-item-avatar>
                <v-icon color="green">mdi-recycle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>Riattiva</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-dialog v-model="dialog_delete" width="400">
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-avatar>
                    <v-icon color="red"> mdi-delete </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title> Elimina </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-card>
                <v-card-title class="error--text"> Elimina </v-card-title>
                <v-card-text v-if="!in_consegna">
                  L'ordine verrà eliminato e non sarà piu recuperabile
                  <br />
                  Sei Sicuro?
                </v-card-text>
                <v-card-text v-else>
                  L'ordine risulta in consegna. Impossibile eliminare
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn text color="primary" @click="dialog_delete = false">
                    Annulla
                  </v-btn>
                  <v-btn
                    v-if="!in_consegna"
                    color="error"
                    :loading="loading_elimina"
                    @click="elimina_ordine()"
                  >
                    <v-icon class="ml-2">mdi-delete</v-icon>
                    Elimina
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-list-item @click="visibilità(!ordine.visibile)">
              <v-list-item-avatar>
                <v-icon color="blue">{{
                  ordine.visibile ? "mdi-eye" : "mdi-eye-off"
                }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  ordine.visibile ? "Nascondi" : "Rivela"
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <UnisciOrdini :iva="ordine.iva" :id="ordine._id.$oid" v-if="ordine.stato == 1"/>
            <RifOrdini
              v-if="ordine.is_joined"
              :lista_uniti="ordine.joined_from"
            />
            <v-list-item @click="toggle_letto()">
              <v-list-item-avatar>
                <v-icon>{{!ordine.nuovo ? 'mdi-email-outline' : 'mdi-email-open-outline'}}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ordine.nuovo ? 'Segna Letto' : 'Segna Da Leggere'}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
</template>

<script>
import ordine_mixin from "../mixin/ordine_attivo_element_mixin"
import UnisciOrdini from "../desktop/ordine_attivo_utilità/unisci_ordini_dialog.vue";
import RifOrdini from "../desktop/ordine_riferimento_unito.vue"
export default {
    components:{
        UnisciOrdini,
        RifOrdini,
    },
    props:{
        ordine: Object
    },
    mixins:[ordine_mixin]
}
</script>