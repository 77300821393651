<template>
  <div>
    <v-autocomplete
      v-model="iva_cliente"
      :items="clienti"
      hide-details
      outlined
      dense
      rounded
      label="Cerca..."
      class="my-6"
      item-text="nome"
      item-value="iva"
      @change="ricerca()"
      clearable
    ></v-autocomplete>
    <v-list>
      <template v-for="ordine in ordini_evasi">
        <OrdineAttivoEl :ordine="ordine" :key="ordine._id.$oid" />
        <v-divider :key="ordine._id.$oid + '-div'" />
      </template>
      <v-btn
        fab
        fixed
        bottom
        right
        color="green"
        dark
        @click="$refs.nuovo_ordine.open()"
        ><v-icon>mdi-plus</v-icon></v-btn
      >
      <NuovoOrdine ref="nuovo_ordine" />
    </v-list>
  </div>
</template>

<script>
import ordini_mixin from "../mixin/ordini_mixin";
import OrdineAttivoEl from "./ordine_attivo_element.vue";
import NuovoOrdine from "./nuovo_ordine/dialog_nuovo_ordine_mobile.vue";
export default {
  components: {
    NuovoOrdine,
    OrdineAttivoEl,
  },
  mixins: [ordini_mixin],
  data() {
    return {
      is_expanded: false,
      height: 390,
    };
  },
  methods: {
    expand() {
      if (this.is_expanded) {
        this.is_expanded = false;
        this.height = 390;
      } else {
        this.is_expanded = true;
        this.height = 700;
      }
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 2px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb;
}
.scroll {
  height: 300px;
  overflow-y: scroll;
}

.scroll_extended {
  height: 610px;
  overflow-y: scroll;
}
</style>