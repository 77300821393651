<template>
  <v-list-item three-line >
    <v-list-item-content @click="apri_dett()">
      <v-list-item-title :class="ordine.nuovo ? 'font-weight-bold' : ''">
        Ordine # {{ ordine.numero }} del {{ ordine.data }}
      </v-list-item-title>
      <v-list-item-subtitle class="text-decoration-underline text-truncate">
        {{ ordine.cliente }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <v-icon :color="stato.color" size="15" class="mr-2">{{
          stato.mdi
        }}</v-icon
        >{{ stato.text }}
        <template v-if="ordine.stato == 2">
          <v-icon size="10" :color="ordine.verde ? 'green' : 'blue'">mdi-circle</v-icon>
        </template>
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="!ordine.visibile">
        <v-icon size="15" class="mr-2">mdi-eye-off</v-icon>Nascosto
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="ordine.is_joined">
        <v-icon color="amber" size="15" class="mr-2">mdi-merge</v-icon>Unito
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Consegna:
        <span :class="ordine.ritiro_cod == 1 ? 'green--text' : 'blue--text'">{{
          ordine.ritiro_cod == 1 ? "Nostra" : "Ritiro"
        }}</span>
        - cc: {{ tot_cc }}
      </v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-list-item-action-text v-if="ordine.nuovo" class="green--text"
        >Nuovo</v-list-item-action-text
      >
      <MenuOpzioni :ordine="ordine" />
    </v-list-item-action>
    <OrdineDettaglio :ordine="ordine" ref="dett_ordine" />
  </v-list-item>
  <!-- <div>
    <v-row
      no-gutters
      align="center"
      :class="ordine.nuovo ? 'font-weight-bold' : ''"
      class="pa-2"
    >
      <v-col cols="1" class="d-flex align-center text-truncate">
        <v-sheet :color="ordine.nuovo ? 'success' : 'white'" height="50" width="5" rounded ></v-sheet>
        <span class="ml-1"># {{ ordine.numero }}</span> <Dettaglio :ordine="ordine"></Dettaglio
      ></v-col>
      <v-col cols="1">
        {{ ordine.data }}
      </v-col>
      <v-col cols="3">
        <v-row no-gutters>
          <v-col cols="12" class="text-truncate">
            {{ ordine.cliente }}
          </v-col>
          <v-col
            cols="12"
            class="green--text mt-n2"
            style="font-size: 10px"
            v-if="ordine.makeByAdmin"
          >
            Creato da Admin
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="1">
        <v-row no-gutters>
          <CambiaTipo :ordine="ordine" />
          <CambiaData :ordine="ordine" />
        </v-row>
      </v-col>
      <v-col cols="1" class="d-flex justify-end pr-2"> € {{ totale }} </v-col>
      <v-col cols="1" class="d-flex justify-end pr-6">
        {{ tot_cc }}
      </v-col>
      <v-col cols="2">
        <v-row no-gutters>
          <v-col
            cols="12"
            @click="apri_modale()"
            :style="ordine.stato == '8' ? 'cursor:pointer' : ''"
          >
            <v-icon :color="stato.color" class="mr-2">{{ stato.mdi }}</v-icon>
            <span :class="stato.color + '--text'">{{ stato.text }}</span>
          </v-col>
          <v-col cols="12" style="font-size: 10px">
            <template v-if="!ordine.visibile">
              <v-icon color="grey" size="15">mdi-eye-off</v-icon>
              Nascosto
            </template>

            <template v-if="ordine.is_joined">
              <v-icon color="amber" size="15" class="ml-2">mdi-merge</v-icon>
              <span class="amber--text"> Unito</span>
            </template>
          </v-col>
        </v-row>
        <DettaglioConteggio
          v-if="ordine.stato == '8'"
          :conteggio="conteggio"
          :ordine="ordine"
          :loading="loading_conteggio"
          ref="modale_conteggio"
        />
      </v-col>
      <v-col cols="2" class="">
        <v-btn
          v-if="ordine.stato == 0"
          icon
          color="green"
          class=""
          :loading="loading_conferma"
          @click="processa_ordine(1)"
        >
          <v-icon> mdi-check </v-icon>
        </v-btn>
        <ArchiviaOrdine v-if="ordine.stato != 0" :id_ordine="ordine._id.$oid" />
        <v-btn icon color="purple" class="mx-1" @click="print_pdf()">
          <v-icon>mdi-printer</v-icon>
        </v-btn>

        <v-menu v-model="menu_opzioni">
          <template v-slot:activator="{ on }">
            <v-btn icon class="mx-1" color="grey" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-dialog v-model="dialog_delete" width="400">
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-avatar>
                    <v-icon color="red"> mdi-delete </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title> Elimina </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-card>
                <v-card-title class="error--text"> Elimina </v-card-title>
                <v-card-text v-if="!in_consegna">
                  L'ordine verrà eliminato e non sarà piu recuperabile
                  <br />
                  Sei Sicuro?
                </v-card-text>
                <v-card-text v-else>
                  L'ordine risulta in consegna. Impossibile eliminare
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn text color="primary" @click="dialog_delete = false">
                    Annulla
                  </v-btn>
                  <v-btn
                    v-if="!in_consegna"
                    color="error"
                    :loading="loading_elimina"
                    @click="elimina_ordine()"
                  >
                    <v-icon class="ml-2">mdi-delete</v-icon>
                    Elimina
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-list-item @click="visibilità(!ordine.visibile)">
              <v-list-item-avatar>
                <v-icon color="blue">{{
                  ordine.visibile ? "mdi-eye" : "mdi-eye-off"
                }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  ordine.visibile ? "Nascondi" : "Rivela"
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <UnisciOrdini :iva="ordine.iva" :id="ordine._id.$oid" />
            <RifOrdini
              v-if="ordine.is_joined"
              :lista_uniti="ordine.joined_from"
            />
            <v-list-item @click="toggle_letto()">
              <v-list-item-avatar>
                <v-icon>{{!ordine.nuovo ? 'mdi-email-outline' : 'mdi-email-open-outline'}}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ordine.nuovo ? 'Segna Letto' : 'Segna Da Leggere'}}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </div> -->
</template>

<script>
// import Dettaglio from "./ordine_dettaglio/ordine_dettaglio_dialog.vue";
import OrdineDettaglio from "./ordine_dettaglio/ordine_dettaglio_mobile.vue";
// import RifOrdini from "./ordine_riferimento_unito.vue";
// import ArchiviaOrdine from "./ordine_attivo_utilità/menu_archivia_ordine.vue";

// import UnisciOrdini from "./ordine_attivo_utilità/unisci_ordini_dialog.vue";
// import CambiaData from "./ordine_attivo_utilità/cambia_data_ordine.vue";
// import CambiaTipo from "./ordine_attivo_utilità/cambia_tipo_consegna.vue";
import ordine_attivo_mixin from "../mixin/ordine_attivo_element_mixin";
import MenuOpzioni from "../common/menu_opzioni.vue";
// import DettaglioConteggio from "@/components/Conteggi/desktop/dettaglio_conteggio/conteggio_dettaglio";

export default {
  components: {
    // Dettaglio,
    // UnisciOrdini,
    // RifOrdini,
    // CambiaData,
    // CambiaTipo,
    // DettaglioConteggio,
    // ArchiviaOrdine,
    MenuOpzioni,
    OrdineDettaglio,
  },
  mixins: [ordine_attivo_mixin],
  props: {
    ordine: Object,
  },
  data() {
    return {
      loading_conferma: false,
      loading_elimina: false,
      dialog_delete: false,
      menu_opzioni: false,
    };
  },
  computed: {
    ritiro() {
      switch (this.ordine.ritiro_cod) {
        case 1:
          return { text: "Consegna", color: "blue", cod: 1 };
        case 0:
          return { text: "Ritiro", color: "green", cod: 0 };
      }
      return { text: "err", color: "red" };
    },
  },
  methods: {
    apri_dett() {
      this.$refs.dett_ordine.open();
    },
  },
};
</script>