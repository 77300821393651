<template>
  <v-row no-gutters align="center" class="py-2">
    <v-col cols="1"> # {{ ordine.numero }} </v-col>
    <v-col cols="1">
      {{ ordine.data }}
    </v-col>
    <v-col cols="3">
      <v-row no-gutters>
        <v-col cols="12" class="text-truncate">
          {{ ordine.cliente }}
        </v-col>
        <v-col
          cols="12"
          class="green--text mt-n2"
          style="font-size: 10px"
          v-if="ordine.makeByAdmin"
        >
          Creato da Admin
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="1">
      <v-row no-gutters>
        <v-col cols="12" :class="ritiro.color + '--text'">
          {{ ritiro.text }}
        </v-col>
        <v-col cols="12" class="mt-n1" style="font-size: 12px">
          {{ ritiro_date ? ritiro_date : "non specificato" }}
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="1" class="d-flex justify-end pr-2">
      € {{totale}}
    </v-col>
    <v-col cols="1"  class="d-flex justify-end pr-6">
      {{ tot_cc }}
    </v-col>
    <v-col cols="2">
      <v-row no-gutters>
        <v-col cols="12">
          <v-icon :color="stato.color" class="mr-2">{{ stato.mdi }}</v-icon>
          <span :class="stato.color + '--text'">{{ stato.text }}</span>
          <v-menu right v-model="menu_cambio_pagamento">
            <template v-slot:activator="{ on }">
              <v-icon
                :color="ordine.verde ? 'green lighten-2' : 'blue'"
                class="ml-2"
                size="10"
                v-on="on"
                >mdi-circle</v-icon
              >
            </template>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-btn
                      icon
                      :disabled="loading_pagamento"
                      :loading="loading_pagamento"
                      @click="set_pagamento(1)"
                    >
                      <v-icon color="blue">mdi-thumb-up</v-icon>
                    </v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-btn
                      icon
                      :disabled="loading_pagamento"
                      :loading="loading_pagamento"
                      @click="set_pagamento(0)"
                    >
                      <v-icon color="green">mdi-thumb-down</v-icon>
                    </v-btn>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12" style="font-size: 10px">
          <template v-if="!ordine.visibile">
            <v-icon color="grey" size="15">mdi-eye-off</v-icon>
            Nascosto
          </template>

          <template v-if="ordine.is_joined">
            <v-icon color="amber" size="15" class="ml-2">mdi-merge</v-icon>
            <span class="amber--text"> Unito</span>
          </template>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="2">
      <Dettaglio :ordine="ordine"></Dettaglio>

      <v-btn icon color="green" class="mx-1" @click="riattiva()">
        <v-icon> mdi-recycle </v-icon>
      </v-btn>

      <v-menu v-model="menu_opzioni">
        <template v-slot:activator="{ on }">
          <v-btn icon class="mx-1" color="grey" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="print_pdf()">
            <v-list-item-avatar>
              <v-icon color="purple">mdi-printer</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title> Stampa </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-dialog v-model="dialog_delete" width="400">
            <template v-slot:activator="{ on }">
              <v-list-item v-on="on">
                <v-list-item-avatar>
                  <v-icon color="red"> mdi-delete </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title> Elimina </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-card>
              <v-card-title class="error--text"> Elimina </v-card-title>
              <v-card-text v-if="!in_consegna">
                L'ordine verrà eliminato e non sarà piu recuperabile
                <br />
                Sei Sicuro?
              </v-card-text>
              <v-card-text v-else>
                L'ordine risulta in consegna. Impossibile eliminare
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text color="primary" @click="dialog_delete = false">
                  Annulla
                </v-btn>
                <v-btn
                  v-if="!in_consegna"
                  color="error"
                  :loading="loading_elimina"
                  @click="elimina_ordine()"
                >
                  <v-icon class="ml-2">mdi-delete</v-icon>
                  Elimina
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-list-item @click="visibilità(!ordine.visibile)">
            <v-list-item-avatar>
              <v-icon>{{ ordine.visibile ? "mdi-eye" : "mdi-eye-off" }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{
                ordine.visibile ? "Nascondi" : "Rivela"
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="mostra_conteggio()">
            <v-list-item-avatar>
              <v-icon color="green">mdi-playlist-check</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title> Conteggio </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <UnisciOrdini :iva="ordine.iva" :id="ordine._id.$oid" /> -->
          <RifOrdini
            v-if="ordine.is_joined"
            :lista_uniti="ordine.joined_from"
          />
        </v-list>
      </v-menu>
    </v-col>
    <DettaglioConteggio
      :loading="loading_conteggio"
      :conteggio="conteggio"
      :ordine="ordine"
      ref="modale_conteggio"
    />
  </v-row>
</template>

<script>
import Dettaglio from "./ordine_dettaglio/ordine_dettaglio_dialog.vue";
// import UnisciOrdini from "./unisci_ordini_dialog";
import ordine_evaso_mixin from "../mixin/ordine_evaso_mixin";
import RifOrdini from "./ordine_riferimento_unito.vue";

import DettaglioConteggio from "@/components/Conteggi/desktop/dettaglio_conteggio/conteggio_dettaglio";

export default {
  components: {
    Dettaglio,
    DettaglioConteggio,
    RifOrdini,
  },
  mixins: [ordine_evaso_mixin],
  props: {
    ordine: Object,
  },
  data() {
    return {
      loading_conferma: false,
      loading_elimina: false,
      dialog_delete: false,
      menu_opzioni: false,
    };
  },
  computed: {
    ritiro() {
      switch (this.ordine.ritiro_cod) {
        case 1:
          return { text: "Consegna", color: "blue", cod: 1 };
        case 0:
          return { text: "Ritiro", color: "green", cod: 0 };
      }
      return { text: "err", color: "red" };
    },
  },
  methods: {},
};
</script>