<template>
  <v-dialog fullscreen persistent v-model="dialog">
    <v-card>
      <v-card-title> Crea Ordine </v-card-title>
      <v-card-text>
        <v-autocomplete
          class="my-2"
          label="Seleziona Cliente"
          v-model="cliente_sel"
          outlined
          :items="clienti"
          item-text="nome"
          item-value="iva"
          hide-details
          @change="ottieni_carrello()"
        ></v-autocomplete>

        <template v-if="cliente_sel">
          <v-card-subtitle> Carrello </v-card-subtitle>
          <template v-if="loading_cart">
            <v-row justify="center">
              <v-progress-circular
                indeterminate
                width="2"
              ></v-progress-circular>
            </v-row>
          </template>
          <template v-else>
            <v-divider />
            <v-list-item>
              <v-list-item-avatar @click="$refs.dialog_prodotto.open()">
                <v-icon>mdi-plus</v-icon>
                <template v-if="prodotto_sel != null">
                  <DialogProdotto :item="prodotto" ref="dialog_prodotto" />
                </template>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="py-2">
                  <v-autocomplete
                    v-model="prodotto_sel"
                    outlined
                    hide-details
                    label="Prodotto extra listino"
                    :items="prodotti"
                    item-text="nome"
                    item-value="id"
                    @change="open_dialog()"
                  >
                    <template v-slot:selection="data">
                      <!-- <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template> -->
                      <template>
                        <v-list-item-avatar size="20">
                          <v-img :src="avatar_img(data.item.id)" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="nome_split(data.item.nome)[0]"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </template>
                    </template>
                    <template v-slot:item="data">
                      <!-- <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template> -->
                      <template>
                        <v-list-item-avatar>
                          <img :src="avatar_img(data.item.id)" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="nome_split(data.item.nome)[0]"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="nome_split(data.item.nome)[1]"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-row
              v-if="!cart_cnt"
              class="py-4"
              justify="center"
              align="center"
              no-gutters
            >
              Nessuno Prodotto nel Carrello
            </v-row>
            <CarrelloEl
              v-for="prodotto in carrello.prodotti"
              :item="prodotto"
              :key="prodotto.art"
              @edit="edit_prod"
            />
          </template>
          <v-row justify="center" class="mt-2" no-gutters>
            <v-col>
              <v-form ref="metodo_form" v-model="metodo_valid">
                <v-radio-group row v-model="metodo_trasporto">
                  <v-radio value="1">
                    <template v-slot:label>
                      <div>Consegna Nostra</div>
                    </template>
                  </v-radio>
                  <v-radio value="0">
                    <template v-slot:label>
                      <div>Ritiro Cliente</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-form>
            </v-col>
          </v-row>
          <v-row justify="center" v-if="metodo_trasporto == 1" no-gutters>
            <v-col cols="12">
              <v-select
                prepend-inner-icon="mdi-google-maps"
                outlined
                label="destinazione"
                v-model="destinazione_sel"
                hide-details
                :items="destinazioni_cliente"
                item-text="indirizzo"
                item-value="indirizzo"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                prepend-inner-icon="mdi-cart"
                outlined
                label="Tipo di carrello"
                v-model="tipo_cc_sel"
                hide-details
                :items="tipo_carrello"
                item-text="text"
                item-value="value"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col>
              <v-switch
              inset
                class="mt-n1"
                hide-details
                v-model="specifica_data"
                label="Specifica Data"
              ></v-switch>
            </v-col>
          </v-row>

          <template v-if="specifica_data">
            <!-- Date Pickers  -->

            <v-row justify="center" @click="set_prima_data()">
              <v-col cols="11">
                Prima data disponibile:
                <br />
                <span
                  style="font-size: 16px; cursor: pointer"
                  class="blue--text font-weight-light"
                  >{{ prima_data.giorno }} ore {{ prima_data.ora }}:00</span
                >
              </v-col>
            </v-row>

            <v-form v-model="orario_valid" ref="orario_form">
              <v-row justify="center" class="">
                <v-col cols="7">
                  <v-menu
                    v-model="modal_date"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="dateFormatter"
                        label="Data di Ritito"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(v) => !!v || 'inserire una data']"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      scrollable
                      locale="it-it"
                      :min="minDate"
                      :max="maxDate"
                      @input="modal_date = false"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="5">
                  <v-menu
                    v-model="modal_time"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="time"
                        label="Ora di Ritito"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :rules="[(v) => !!v || 'inserire un orario']"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      :min="minHour"
                      v-if="modal_time"
                      v-model="time"
                      full-width
                      format="24hr"
                      @click:hour="closePiker"
                    />
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </template>
          <v-row>
            <v-col>
              <v-textarea
                label="Commento Finale"
                outlined
                v-model="commento"
                height="75px"
              ></v-textarea>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          :disabled="!cart_cnt"
          outlined
          color="primary"
          :loading="on_ending"
          @click="concludi_ordine()"
        >
          <v-icon class="mr-2"> mdi-cart </v-icon>
          concludi ordine
        </v-btn>
        <v-btn text color="grey" @click="close()">Chiudi</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import nuovo_ordine_mixin from "../../mixin/nuovo_ordine_mixin/crea_nuovo_ordine_mixin";
import concludi_ordine_mixin from "../../mixin/nuovo_ordine_mixin/concludi_ordine_mixin";
import DialogProdotto from "./dialog_carrello_mobile.vue";
import datepicker_mixin from "../../mixin/nuovo_ordine_mixin/date_picker_mixin";

import CarrelloEl from "./carrello_element_mobile.vue";
export default {
  components: {
    CarrelloEl,
    DialogProdotto,
  },
  mixins: [nuovo_ordine_mixin, concludi_ordine_mixin, datepicker_mixin],
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    $route(to, from) {
      console.log("route change");
      console.log(to);
      console.log(from);
      if (from.hash === "#dettaglio-prodotto" && to.hash === "#nuovo-ordine") {
        return;
      }
      if (to.hash === "") {
        console.log("close");
        this.dialog = false;
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.$router.push("#nuovo-ordine");
    },
    close() {
      this.show = false;
      this.$router.back();
    },
  },
};
</script>