<template>
  <div>
    <v-list-item @click="show_color = !show_color">
      <v-list-item-avatar>
        <v-img :src="avatar_img"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          {{ nome[0] }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ nome[1] ? nome[1] : "Vaso Singolo" }}
        </v-list-item-subtitle>
        <v-list-item-subtitle> € {{ prezzo_tot }} </v-list-item-subtitle>
        <v-list-item-subtitle class="text-truncate">
          {{ item.commento }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-list-item-action-text>
          <span class="font-weight-bold" style="font-size: 16px">{{
            tot_pezzi
          }}</span>
        </v-list-item-action-text>
      </v-list-item-action>
    </v-list-item>

    <v-slide-y-transition group>
      <template v-if="show_color">
        <v-list-item v-for="colore in item.colori" :key="colore.id">
          <v-list-item-avatar>
            <v-avatar size="30">
              <v-img :src="colore_img(colore.id)"></v-img>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span class="mr-4 grey--text">{{ colore.numero }}</span>
              <span class="font-italic grey--text">{{ colore.nome }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-row no-gutters :key="0">
          <v-col cols="6">
            <v-list-item @click="$emit('edit', item.art)">
              <v-list-item-avatar>
                <v-icon>mdi-pencil</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span class="font-italic grey--text">Modifica ...</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="6">
            <v-dialog v-model="del_dialog" width="400">
              <template v-slot:activator="{ on }">
                <v-list-item v-on="on">
                  <v-list-item-avatar>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="font-italic grey--text">Elimina ...</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-card>
                <v-card-title class="red--text"> Sei sicuro ? </v-card-title>
                <v-card-actions>
                  <v-spacer />
                  <v-btn text color="grey" @click="del_dialog = false"
                    >annulla</v-btn
                  >
                  <v-btn outlined color="red" @click="delete_prod()"
                    >Elimina</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </template>
    </v-slide-y-transition>

    <v-divider />
  </div>
</template>

<script>
import carrello_element_mixin from "../../mixin/nuovo_ordine_mixin/carrello_element_mixin";
export default {
  mixins: [carrello_element_mixin],
  data() {
    return {
      del_dialog: false,
    };
  },
  methods: {
    delete_prod() {
      this.$store
        .dispatch("remove_prodotto_carrello", this.item.art)
        .then(() => {
          // this.$emit("delete");
          this.del_dialog = false;
        })
        .catch((err) => {
          if (err.response.status == 400) {
            this.$router.push("/login");
          }
        });
    },
  },
};
</script>
